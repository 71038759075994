<template>
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
                <slot>
                    <v-btn :color="openButtonColor">{{ openButtonText }}</v-btn>
                </slot>
            </div>
        </template>
        <v-card>
          <v-card-title>{{ dialogTitle }}</v-card-title>
          <v-card-text>
            <slot name="information"></slot>
            <v-row v-if="showCommentForm">
              <v-col cols="12">
                <v-form ref="commentForm">
                <slot name="fields"></slot>
                 <v-textarea
                  :label="commentTitle"
                  :placeholder="commentPlaceholder"
                  dense
                  outlined
                  flat
                  multiline
                  v-model="comment"
                  :rules="[validators.required]"
                ></v-textarea>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="CloseDialog">Close</v-btn>
            <v-btn color="success" @click="Confirm">{{ confirmText }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
import * as Validators from '@/datahelpers/validation'
import * as FrameworkValidators from '@core/utils/validation'

export default {
    props: {
        showCommentForm: {
            type: Boolean,
            default: true
        },
        openButtonText: {
            type: String,
            default: "Confirm"
        },
        openButtonColor: {
            type: String,
            default: 'primary'
        },
        commentTitle: {
            type: String,
            default: 'Comment'
        },
        commentPlaceholder: {
            type: String,
            default: 'Please explain this action.'
        },
        confirmText: {
            type: String,
            default: 'Confirm'
        },
        dialogTitle: {
            type: String,
            required: true
        }
    },
    data: function() {
        return {
            showDialog: false,
            comment: "",
            validators: {
            ...FrameworkValidators,
            ...Validators
            },
            userData: null
        }
    },
    methods: {
        CloseDialog() {
            if (this.showCommentForm) {
                this.$refs.commentForm.reset();
            }
            this.comment = "";
            this.showDialog = false;
        },
        Confirm() {
            if (!this.showCommentForm || this.$refs.commentForm.validate()) {

                if (this.userData) {
                    this.$emit('confirmed', {
                        comment: this.comment,
                        userData: this.userData
                    });
                } else {
                    this.$emit('confirmed', this.comment);
                }
                this.CloseDialog();
            }
        }
    },
    mounted() {
        // emit a function to show/hide programatically
        this.$emit('toggleControl', (show, userData) => {
            this.showDialog = show;
            this.userData = userData;
        });
    }
}
</script>